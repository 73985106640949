globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"a872b1060d7210f541f06d60fd4d94a9ab4f74ce"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://574d2a0272108f2a02c435285e26c474@o4504253510647808.ingest.us.sentry.io/4507174661521408",

  ignoreErrors: [
    'Non-Error',
    'CustomEvent',
    'InvalidStateError',
    'anonymous',
    'tracking',
    'react-masonry',
    'getItem',
    '(type=error) captured as promise rejection',
    'Failed to read the \'localStorage\'',
    'Cannot read properties of undefined (reading \'domInteractive\')',
    'domInteractive',
    'Cannot read properties of undefined (reading \'banner.text\')',
    'banner.text',
    'Cannot read properties of null (reading \'__transferData\')',
    '__transferData',
    'Cannot read properties of null (reading \'removeItem\')',
    'removeItem',
    'The request is not allowed by the user agent or the platform',
    'Unexpected token',
    /CookieSpool/i,
    /banner\.text/i,
    'checkPerfReady',
    'globalThis',
    'Connection closed',
    /^0$/,
    /^Error: 0$/,
    'Failed to fetch',
    'Load failed',
    'The operation is insecure',
    'The operation was aborted',
    'JSONP request failed',
    'Java object is gone',
    'vazgeçildi',
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
